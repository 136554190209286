import { createMuiTheme } from '@material-ui/core/styles';

console.log(createMuiTheme);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#154889', // rgb(21, 72, 137) - синий цвет из брендбука
      dark: '#12407b', // на 10% темнее синего цвета из брендбука
      light: '#2c5a94', // на 10% светлее синего цвета из бренбука
      contrastText: '#fefefe', // rgb(254, 254, 254) - белый цвет из брендбука
    },
    text: {
      primary: '#333', // графитовый цвет из брендбука не используется, он слишком светлый
      disabled: '#b2b3b3', // rgb(178, 179, 179) - серый цвет из брендбука
    }
  }
});

console.log(`theme= `, theme); 

export {
  theme,
};

import React, { Component } from 'react';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import {  Checkbox, FormControlLabel, ThemeProvider, Typography } from '@material-ui/core';

import icon from './images/cart.svg';
import './style.css';
import { theme } from '../context/theme';

const initialState = {
  consentFlag: false,
  dialogVisible: false,
  email: '',
  loading: false,
  name: '',
  orderContent: '',
  phone: '7',
  submitted: false,
  success: false,
};

class OnlineOrderForm extends Component {
  state = initialState;

  handleButtonClick = () => {
    this.setState({ dialogVisible: true });
  };

  handleDialogClose = () => {
    this.setState({ dialogVisible: false });
    if (this.state.success) {
      this.setState(initialState);
    }
  };

  send = () => {
    const {
      email,
      orderContent,
      name,
      phone,
    } = this.state;

    return axios.post('/create-order.html', {
      email,
      name,
      orderContent,
      phone,
    });
  };

  setConsent = (event) => {
    this.setState({ consentFlag: event.target.checked });
  };

  setEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  setName = (event) => {
    this.setState({ name: event.target.value });
  };

  setOrderContent = (event) => {
    this.setState({ orderContent: event.target.value });
  };

  setPhone = (event) => {
    this.setState({ phone: event.target.value });
  };

  submit = (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    if (this.validate()) {
      this.setState({
        loading: true,
        serverError: '',
      });
      this.send()
        .then(() => {
          this.setState({ success: true });
        })
        .catch(() => {
          this.setState({
            loading: false,
            serverError: 'Ошибка при отправке заявки. Пожалуйста, проверьте соединение с интернетом и попробуйте ещё раз. Если ошибка повторяется, пожалуйста, оформите заказ через мессенджер.',
          });
        });
    }
  };

  validate = () => {
    const { phone } = this.state;
    return /^\+7 [\d]{3} [\d]{3} [\d]{4}$/.test(phone);
  };

  render() {
    const {
      consentFlag,
      dialogVisible,
      email,
      loading,
      name,
      orderContent,
      phone,
      serverError,
      submitted,
      success,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <div
          className="online-order-icon__flex"
          onClick={this.handleButtonClick}
        >
          <img
            src={icon}
            className="online-order-icon__image"
            alt=""
          />
          <span>заказать онлайн</span>
        </div>
        <Dialog
          open={dialogVisible}
          onClose={this.handleDialogClose}
          fullWidth
        >
          <DialogTitle
            className="mounting-request-dialog-title"
            disableTypography
          >
            <Typography variant="h6">
              Закажите товары онлайн
            </Typography>
            <IconButton aria-label="close" onClick={this.handleDialogClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <form className="online-order-form" onSubmit={this.submit}>
              {success &&
                <div className="success-overlay">
                  <CheckCircleOutlineRoundedIcon
                    style={{
                      color: green[500],
                      fontSize: 120,
                      margin: '2rem',
                    }}
                  />
                  <br />
                  <div style={{ fontSize: '1.5rem' }}>
                    Заказ принят. Мы свяжемся с Вами в ближайшее время.
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={this.handleDialogClose}
                  >
                    Готово
                  </Button>
                </div>

              }
              <InputMask
                disabled={loading}
                mask="+7 999 999 9999"
                maskChar=""
                value={phone}
                onChange={this.setPhone}
              >
                {() =>
                  <TextField
                    autoFocus
                    error={submitted && phone.length < 10}
                    id="outlined-basic"
                    label="Контактный телефон"
                    margin="normal"
                    required
                    variant="outlined"
                  />
                }
              </InputMask>
              <TextField
                id="outlined-basic"
                disabled={loading}
                margin="normal"
                label="Ваше имя"
                variant="outlined"
                required
                value={name}
                onChange={this.setName}
              />
              <TextField
                id="outlined-basic"
                disabled={loading}
                label="E-mail (необязательно)"
                margin="normal"
                variant="outlined"
                value={email}
                onChange={this.setEmail}
              />

              <TextField
                id="standard-multiline-flexible"
                disabled={loading}
                label="Ваш заказ"
                helperText="Укажите код товара (состоит из 4-6 цифр) и количество. Вы также можете указать требуемые характеристики товара."
                margin="normal"
                multiline
                rows={4}
                variant="outlined"
                value={orderContent}
                onChange={this.setOrderContent}
              />

              <Box my="0.5rem">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={consentFlag}
                      disableRipple
                      onChange={this.setConsent}
                      name="consentFlag"
                      color="primary"
                      required
                    />
                  }
                  label={<span>
                    Я даю согласие на&nbsp;обработку персональных данных в соответствии с&nbsp;
                    <a href="https://revansh.info/%D0%BF%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0-%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8-%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.html" target="_blank" rel="noopener noreferrer">
                      Политикой
                    </a>
                    </span>
                  }
                />
              </Box>
              
              <Box my="1rem">
                <Button
                  disabled={loading || success || !consentFlag}
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                >
                  Отправить заказ
                </Button>
              </Box>
                          
              {!!serverError &&
                <Alert
                  variant="outlined"
                  severity="error"
                >
                  {serverError}
                </Alert>
              }
            </form>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  }
}

export default OnlineOrderForm;
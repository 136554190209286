/*
import React from 'react';

import { OnlineOrderForm } from './OnlineOrderForm';
import './App.css';*/

function App() {
  console.log('App component is ready');
  return null;
}

export default App;

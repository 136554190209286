import React, { useState } from 'react';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DropzoneArea } from 'material-ui-dropzone';
import FormData from 'form-data';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { useWindowSize } from "@uidotdev/usehooks";
import { theme } from '../context/theme';

import './style.css';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const ACCEPTED_FILE_TYPES = ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx',
  '.txt', '.rtf', '.ods', '.odt', '.pdf', '.jpeg', '.jpg', '.png', '.rar', '.zip', '.7z'];

const MAX_FILE_SIZE = 5 * 1024 * 1024;

function validateEmail(value, final = false) {
  // final означает более строгую проверку e-mail-а, введённого до конца
  // return value: true - valid, false - invalid
  if (final) {
    // eslint-disable-next-line no-useless-escape
    return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value);
  }

  return /^[^\s]+$/.test(value);
}

export function TenderInvitationForm() {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [consentFlag, setConsentFlag] = useState(false);
  const [phone, setPhone] = useState('');
  const [extensionNumber, setExtensionNumber] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [serverError, setServerError] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { width } = useWindowSize();

  const handleFileChange = (files) => {
    setSelectedFiles(files);
  }
  
  const setEmailAndValidate = (value) => {
    setEmailError(!validateEmail(value));
    setEmail(value);
  }

  const setDialogClosed = () => {
    setDialogVisible(false);
    if (success) {
      setSuccess(false);
      setSubmitted(false);
      setName('');
      setCompanyName('');
      setPhone('');
      setExtensionNumber('');
      setEmail('');
      setEmailError(false);
    }
  };

  const validate = () => {
    return !emailError && /^\+7 [\d]{3} [\d]{7}$/.test(phone);
  };

  const send = () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('companyName', companyName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('extensionNumber', extensionNumber);
    selectedFiles.forEach((file) => {
      formData.append('files[]', file);
    });

    return axios.post('api/tenders/invite/', formData);
  };

  const setConsent = (event) => {
    setConsentFlag(event.target.checked);
  };

  const submit = (event) => {
    event.preventDefault();
    setEmailError(!validateEmail(email, true));
    setSubmitted(true);
    if (validate()) {
      setLoading(true);
      setServerError('');
      send()
        .then(() => {
          setLoading(false);
          setSuccess(true);
        })
        .catch(() => {
          setLoading(false);
          setServerError('Ошибка при отправке заявки. Пожалуйста, проверьте соединение с интернетом и попробуйте ещё раз. Если ошибка повторяется, пожалуйста, сообщите нам в мессенджерах или через форму обратной связи.');
        });
    } else {
      console.log('Ошибка');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Button
        color="inherit"
        size="medium"
        disableElevation
        disableFocusRipple
        disableRipple
        onClick={() => setDialogVisible(true)}
      >
        Участвуем в тендерах
      </Button>
      {dialogVisible &&
        <Dialog
          open={dialogVisible}
          onClose={setDialogClosed}
          fullWidth
          fullScreen={width < 450}
        >
          <DialogTitle
            disableTypography
            className="tender-invitation-dialog-title"
          >
            <Typography variant="h6">
              Отправьте нам приглашение в тендер
            </Typography>
            <IconButton aria-label="close" onClick={setDialogClosed}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <form className="tender-invitation-form" onSubmit={submit}>
              {(success || loading) &&
                <div className="success-overlay tender-invitation-form__success-overlay">
                  {success &&
                    <>
                      <CheckCircleOutlineRoundedIcon
                        style={{
                          color: green[500],
                          fontSize: 120,
                          margin: '0 2rem 2rem',
                        }}
                      />
                      <br />
                      <Box my="1rem">
                        <Typography variant="h6">
                          Спасибо за запрос.<br />В случае заинтересованности мы свяжемся с Вами.
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={setDialogClosed}
                      >
                        Готово
                      </Button>
                    </>
                  }
                  {loading &&
                    <CircularProgress />
                  }
                </div>
              }
              <TextField
                id="outlined-basic"
                autoFocus
                disabled={loading}
                margin="normal"
                label="Название компании/ИП"
                variant="outlined"
                required
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
              />
              <TextField
                id="outlined-basic"
                disabled={loading}
                margin="normal"
                label="ФИО контактного лица"
                variant="outlined"
                required
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <InputMask
                  disabled={loading}
                  mask="+7 999 9999999"
                  maskChar=""
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                >
                  {() =>
                    <TextField
                      error={submitted && phone.length < 10}
                      id="outlined-basic"
                      label="Контактный телефон"
                      margin="normal"
                      required
                      variant="outlined"
                    />
                  }
                </InputMask>
                <InputMask
                  disabled={loading}
                  mask="999999"
                  maskChar=""
                  value={extensionNumber}
                  onChange={(event) => setExtensionNumber(event.target.value)}
                >
                  {() =>
                    <TextField
                      id="outlined-basic"
                      label="Добавочный (если есть)"
                      margin="normal"
                      variant="outlined"
                    />
                  }
                </InputMask>
              </Box>
              <TextField
                id="outlined-basic"
                error={emailError}
                disabled={loading}
                label="E-mail"
                margin="normal"
                required
                variant="outlined"
                value={email}
                onChange={(event) => setEmailAndValidate(event.target.value)}
              />

              <Box my="0.5rem">
                <Box my="0.5rem">
                  <Typography variant="subtitle1">
                    Прикрепите ТЗ или проект
                  </Typography>
                </Box>
                <DropzoneArea
                  acceptedFiles={ACCEPTED_FILE_TYPES}
                  alertSnackbarProps={{ autoHideDuration: 6000 }}
                  dropzoneText="Перетащите файлы сюда или нажмите, чтобы загрузить"
                  getDropRejectMessage={(rejectedFile, _, maxFileSize) => `Файл ${rejectedFile.path} отклонён. Макс. размер файла - ${Math.floor(maxFileSize / 1024)} КБ. Разрешены файлы: ${ACCEPTED_FILE_TYPES.map(item => item.substring(1).toUpperCase()).join(', ')}. `}
                  getFileAddedMessage={(filename) => `\r\nФайл ${filename} добавлен. `}
                  getFileLimitExceedMessage={(filesLimit) => `Можно прикреплять не более ${filesLimit} файлов. `}
                  getFileRemovedMessage={(filename) => `\r\nФайл ${filename} удалён. `}
                  filesLimit={6}
                  maxFileSize={MAX_FILE_SIZE}
                  onChange={handleFileChange}
                  previewText="Файлы:"
                  showPreviews
                  showFileNamesInPreview
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                />
              </Box>

              <Box my="0.5rem">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={consentFlag}
                      disableRipple
                      onChange={setConsent}
                      name="consentFlag"
                      color="primary"
                      required                      
                    />
                  }
                  label={<span>
                    Я даю согласие на&nbsp;обработку персональных данных в соответствии с&nbsp;
                    <a href="https://revansh.info/%D0%BF%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0-%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8-%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.html" target="_blank" rel="noopener noreferrer">
                      Политикой
                    </a>
                    </span>
                  }
                />
              </Box>

              <Box my="1rem">
                <Button
                  disabled={loading || success || !consentFlag}
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                >
                  Отправить заявку
                </Button>
              </Box>

              {!!serverError &&
                <Alert
                  variant="outlined"
                  severity="error"
                >
                  {serverError}
                </Alert>
              }
            </form>
          </DialogContent>
        </Dialog>
      }
    </ThemeProvider>
  );
}
import React from 'react';
import ReactDOM from 'react-dom';
import { MountingRequestForm } from './MountingRequestForm';
import { OnlineOrderForm } from './OnlineOrderForm';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { TenderInvitationForm } from './TenderInvitationForm/TenderInvitationForm';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('spa-root')
);

// Начало: форма заявки на установку техники
if (document.getElementById('mounting-banner__button')) {
  ReactDOM.render(
    <React.StrictMode>
      <MountingRequestForm />
    </React.StrictMode>,
    document.getElementById('mounting-banner__button'),
  );
}
// Конец: форма заявки на установку техники

// Начало: форма онлайн-заказа
if (document.getElementById('online-order-icon')) {
  ReactDOM.render(
    <React.StrictMode>
      <OnlineOrderForm />
    </React.StrictMode>,
    document.getElementById('online-order-icon'),
  );
}
// Конец: форма онлайн-заказа

// Начало: форма приглашения на тендер
if (document.getElementById('tender-invitation-button')) {
  ReactDOM.render(
    <React.StrictMode>
      <TenderInvitationForm />
    </React.StrictMode>,
    document.getElementById('tender-invitation-button'),
  );
}
// Конец: форма приглашения на тендер

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
